import React from 'react';

function EducationalHistory() {
    return (
        <div className='educational-history'>
            <h1>Educational History</h1>

            <div class="educational-item">
                <h3>Bachelor of Science (Computer Science)</h3>
                <p class="campus">University of Queensland, Brisbane, QLD</p>
                <p class="time-period">January 2020 - Nov 2024</p>
                <p class="educational-description">
                    In 2020, I embarked on a Bachelor in Computer Science at the University of Queensland.
                    Throughout my studies, I've gained a robust skillset through both theory and hands-on 
                    experience, which I'm eager to put into action. My coursework has covered key areas like
                    cyber security, software engineering, and data science. I'm also completing a minor in 
                    Japanese language! Some key areas I've covered include:
                </p>
                <ul class="educational-points">
                    <li>Python, Java, C & Javascript coding</li>
                    <li>Logical & discrete mathematics</li>
                    <li>Networking principles</li>
                    <li>Database design & management</li>
                    <li>Cloud computing</li>
                    <li>Algorithms & data structures</li>
                </ul>
            </div>
            
        <div style={{ height: '20px' }}></div>

            <div class="educational-item">
                <h3>Diploma of Building Design & Technology</h3>
                <p class="campus">Southbank Institute of Technology</p>
                <p class="time-period">January 2012 - Nov 2013</p>
                <p class="educational-description">
                    While studying the diploma of building design and technology, I acquired a comprehensive
                    set of skills. These skills encompassed architectural drafting (using both CAD software 
                    and hand-drawn plans), local building codes and regulations, integration of building systems,
                    sustainable design principles, site analysis, 3D modelling, project management, effective
                    communcation for collaboration, material selection, and the art of presenting design concepts
                    persuasively.
                </p>
            </div>

        <div style={{ height: '20px' }}></div>

            <div class="educational-item">
                <h3>Senior Certificate</h3>
                <p class="campus">St. Laurence's College</p>
                <p class="educational-description">
                    I completed high school at St. Laurence's College, where I 
                </p>
            </div>
            
        </div>
    );
}

export default EducationalHistory;
