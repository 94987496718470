import React from 'react';

function PersonalInfo() {
    return (
        <div className='personal-info'>
            
        </div>
    );
}

export default PersonalInfo;