import Introduction from "../components/homePageComponents/Introduction.js";
import Skills from "../components/homePageComponents/Skills.js";
  
  function HomePage() {
    return (
      <div>
        <div style={{ height: '100px' }}></div>
        <Introduction />
        <div style={{ height: '200px' }}></div>
        <Skills />
        <div style={{ height: '250px' }}></div>

      </div>
    );
  }
  
  export default HomePage;                                                                                                                                                                                                                                                                  