import React from 'react';

function MagicPrivacy() {
    return (
        <div className='magic-privacy'>
            <div class="text-content">
                <h1>Privacy Policy</h1>

                <h3>Are we going to steal and sell your private data?</h3>
                <p>We promise to never do anything malicious with your data.</p>

                <h3>What private data do we keep?</h3>
                <p>The only personal data we have from you is your username and
                    password (and we can't even see your password).
                </p>

                <h3>Where is the data stored?</h3>
                <p>We use Google's Firebase to store all of our data. Since it's
                    hosted & run by Google, it is very safe.
                </p>

                <h3>Who is the data shared with?</h3>
                <p>We do not share this data with any third parties</p>

                <h3>How long do you keep this data?</h3>
                <p>We keep the data as long as you remain a user of this service,
                    or until you request it is deleted.
                </p>

                <h3>Can your data be deleted?</h3>
                <p>Sure! Just send us an email at 10.to.1.magic@gmail.com, letting
                    us know your username and that you'd like us to remove your data,
                    and we can remove all your personal data immediately.
                </p>

                <p>Majikku Studios 2024</p>
            </div>
        </div>
    );
}

export default MagicPrivacy;